<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div>
      <h2 class="my-4 text-4xl font-semibold">Import Units</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Import units from CSV file</p>
      </div>
    </div>

    <!-- Units Content -->
    <div class="w-full">
      <div class="mt-5">
        <CsvUpload v-model="records"> </CsvUpload>
        <div v-if="records && records.length > 0">
          <p>{{ records.length }} ready to be imported.</p>
        </div>
      </div>
    </div>

    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        @click.prevent="importRecords"
        :disabled="!records"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Import
      </button>
      <div v-if="importing">
        {{ noImported }} of {{ records.length }} imported.
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { unitsCollection } from "../../firebase.js";
import CsvUpload from "../../components/admin/CsvUpload";
import { mapGetters } from "vuex";

export default {
  name: "ImportUnits",
  components: {
    CsvUpload,
  },
  data: () => ({
    records: null,
    noImported: 0,
    importing: false,
  }),
  methods: {
    importRecords() {
      this.importing = true;
      this.records.forEach((record) => {
        let unitData = record;

        let stringFields = [
          "mainImageURL",
          "slide2ImageURL",
          "slide3ImageURL",
          "slide4ImageURL",
          "slide5ImageURL",
        ];

        let booleanFields = [
          "customerHasAccount",
          "setGlobalDiscountPrice",
          "setOneDayOnlyDiscount",
          "displayOnHomePage",
          "isContractReceived",
          "isDoubleGlaze",
          "isGuaranteedRental",
          "isOneDayOnlyDiscount",
          "isOverrideAction",
          "isPurchasedTrust",
          "isSold",
          "isUpgradedFinishes",
          "isUnitParking",
          "statusPayStack",
          "isAddAdditionalExtras",
          // "isUnitAircon",
          "isAircon",
        ];
        let numberFields = [
          "price",
          "purchasePrice",
          "levies",
          "rates",
          "guaranteedRental",
          "oneDayOnlyDiscount",

          "bathrooms",
          "bedrooms",
          "parking",
          "storeroom",

          "internalArea",
          "externalArea",
          "totalArea",

          "doubleGlazed",
          "upgradedFinishes",

          "unitDepositPercentage",
          "depositTotalDue",
          "dueDateDeposit",
          "depositAmount",
          "depositBalancePaid",
          "totalSalesPriceInclVat",
          "dueDateFinanceApproval",
          "dueDateBalanceSecured",
          "totalExtras",
      ];

        stringFields.forEach((field) => {

          if (unitData[field] === "") {
            unitData[field] = null;
          } else {
            unitData[field];
          }
        });

        booleanFields.forEach((field) => {
          if (
            unitData[field] &&
            (typeof unitData[field] === "string" ||
              unitData[field] instanceof String)
          ) {
            if (unitData[field] === "TRUE") {
              unitData[field] = true;
            } else {
              unitData[field] = false;
            }
          }
        });

        numberFields.forEach((field) => {
          if (
            unitData[field] &&
            (typeof unitData[field] === "string" ||
              unitData[field] instanceof String)
          ) {
            unitData[field] = parseInt(unitData[field], 10);
          }
        });

        let timestamp = firebase.firestore.Timestamp.now();
        if (!unitData.status) {
          unitData.status = "available";
        }
        unitData.created = timestamp;
        unitData.modified = timestamp;
        unitData.addedBy = this.user.profile.email;
        unitData.modifiedBy = this.user.profile.email;

        unitsCollection
          .add(unitData)
          .then(() => {
            this.noImported++;
            if (this.noImported === this.records.length) {
              this.importing = false;
              this.$notify({
                group: "global",
                type: "success",
                title: "All units imported",
              });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Error importing units",
              text: error.message,
            });
          });
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  created() {},
};
</script>

<style scoped>
.o-btn {
  @apply bg-primary;
}
</style>
